import '@/shared/utils/prelude';
import 'mac-scrollbar/dist/mac-scrollbar.css';
import '../styles/globals.css';
import '@rainbow-me/rainbowkit/styles.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import * as Sentry from '@sentry/react';
import { Toaster } from 'sonner';
import FallbackLayout from '@/shared/components/organisms/FallbackLayout';
import { Provider as RainbowKitWrappedProvider } from '@/shared/components/RainbowKit';
import { Provider as SolanaProvider } from '@/shared/components/SolanaConnect';
import { PriceOracleProvider } from '@/shared/hooks';
import usePlausible from '@/shared/hooks/usePlausible';
import { getChainflipNetwork, initSentry } from '@/shared/utils';
import { swapRainbowConfig } from '@/shared/utils/rainbowkit';
import Layout from '../components/Layout';
import { ChainBalancesProvider } from '../hooks/useChainBalances';
import { SWAPPING_APP_DOMAINS } from '../utils/consts';

initSentry();

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  usePlausible();

  const network = getChainflipNetwork();
  const domain = SWAPPING_APP_DOMAINS[network === 'unknown' ? 'perseverance' : network];
  const ogType = network === 'mainnet' ? 'mainnet' : 'testnet';

  return (
    <>
      <Head>
        <link rel="icon" href="/chainflip-favicon.ico" sizes="any" />
        <link rel="icon" href="/chainflip-favicon.svg" type="image/svg+xml" />

        <title>Chainflip Swap</title>
        <meta
          name="description"
          content="An efficient cross-chain AMM enabling native asset swaps without wrapped tokens or specialized wallets"
        />

        <meta property="og:url" content={`https://${domain}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Chainflip Swap" />
        <meta
          property="og:description"
          content="An efficient cross-chain AMM enabling native asset swaps without wrapped tokens or specialized wallets"
        />
        <meta property="og:image" content={`https://${domain}/chainflip-${ogType}-og.png`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={domain} />
        <meta property="twitter:url" content={`https://${domain}`} />
        <meta name="twitter:title" content="Chainflip Swap" />
        <meta
          name="twitter:description"
          content="An efficient cross-chain AMM enabling native asset swaps without wrapped tokens or specialized wallets"
        />
        <meta name="twitter:image" content={`https://${domain}/chainflip-${ogType}-og.png`} />
      </Head>
      <Script
        id="next-plausible-init"
        dangerouslySetInnerHTML={{
          __html: `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`,
        }}
      />
      <Sentry.ErrorBoundary
        // eslint-disable-next-line react/no-unstable-nested-components
        fallback={({ error, resetError }) => (
          <FallbackLayout message={error.message} onClose={resetError} />
        )}
      >
        <RainbowKitWrappedProvider config={swapRainbowConfig}>
          <SolanaProvider cfNetwork={network}>
            <PriceOracleProvider>
              <ChainBalancesProvider>
                <Layout>
                  <Component {...pageProps} />
                  <Toaster
                    theme="dark"
                    richColors
                    toastOptions={{
                      style: {
                        fontSize: '12px',
                        fontFamily: 'Aeonik-Medium',
                      },
                    }}
                  />
                </Layout>
              </ChainBalancesProvider>
            </PriceOracleProvider>
          </SolanaProvider>
        </RainbowKitWrappedProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default MyApp;
