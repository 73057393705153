import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type ChainId } from '@/shared/assets/chains';
import { type Token } from '@/shared/assets/tokens';
import { deduplicater } from '@/shared/utils';
import { integrationManager } from '../integrations';

const deduplicateTokens = deduplicater<Token>((t) => t.address.toLowerCase());

export const useTokens = (
  sourceChainId: ChainId | undefined,
): { tokens: Token[]; isLoading: boolean } => {
  const { data: chainflipTokens, isLoading: chainflipLoading } = useQuery({
    queryKey: ['CHAINFLIP_TOKENS', sourceChainId],
    enabled: Boolean(sourceChainId),
    queryFn: async () => integrationManager.getIntegration('chainflip').getTokens(sourceChainId!),
  });

  const { data: squidTokens, isLoading: squidLoading } = useQuery({
    queryKey: ['SQUID_TOKENS', sourceChainId],
    enabled: Boolean(sourceChainId),
    queryFn: async () => integrationManager.getIntegration('squid').getTokens(sourceChainId!),
  });

  const tokens = useMemo(
    () => deduplicateTokens([...(chainflipTokens ?? []), ...(squidTokens ?? [])]),
    [chainflipTokens, squidTokens],
  );

  return { tokens, isLoading: chainflipLoading || squidLoading };
};
