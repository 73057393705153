export const cloneObjects = <T extends NonNullable<unknown>>(objects: T[]): T[] =>
  objects.map((o) => ({ ...o }));

export const deduplicater =
  <T>(cb: (o: T) => unknown) =>
  (objects: T[]): T[] => {
    const seen = new Set();

    return objects.filter((o) => {
      const key = cb(o);
      if (seen.has(key)) return false;
      seen.add(key);
      return true;
    });
  };
