import { type SwapStatusResponseV2 } from '@chainflip/sdk/swap';
import { type StatusResponse } from '../integrations';
import { cfIsBoostedChannel, cfIsBoostSkipped, cfIsSwapBoosted } from '../integrations/chainflip';

export const getSwapObject = (status: StatusResponse) =>
  status.integrationData && 'swap' in status.integrationData && status.integrationData.swap
    ? status.integrationData.swap
    : undefined;

export const getRefundEgress = (status: StatusResponse) =>
  status.integrationData &&
  'refundEgress' in status.integrationData &&
  status.integrationData.refundEgress
    ? status.integrationData.refundEgress
    : undefined;

export const getSwapFailure = (status: StatusResponse) =>
  status.integrationData &&
  'swapEgress' in status.integrationData &&
  status.integrationData.swapEgress &&
  'failure' in status.integrationData.swapEgress
    ? status.integrationData.swapEgress.failure
    : undefined;

export const getRefundFailure = (status: StatusResponse) =>
  status.integrationData &&
  'refundEgress' in status.integrationData &&
  status.integrationData.refundEgress &&
  'failure' in status.integrationData.refundEgress
    ? status.integrationData.refundEgress.failure
    : undefined;

export const isDepositReceived = (status: StatusResponse) => {
  if (status.integration === 'chainflip' && status.integrationData?.state !== 'WAITING') {
    if (!status.integrationData?.depositChannel) {
      return Boolean(status.integrationData?.deposit.amount);
    }
    return Boolean(status.integrationData?.deposit.receivedAt);
  }
  return false;
};

export const isBoostedChannel = (status: StatusResponse) =>
  status.integration === 'chainflip' && cfIsBoostedChannel(status.integrationData);

export const isBoostSkipped = (status: StatusResponse) =>
  status.integration === 'chainflip' && cfIsBoostSkipped(status.integrationData);

export const isSwapBoosted = (status: StatusResponse) =>
  status.integration === 'chainflip' && cfIsSwapBoosted(status.integrationData);

export const isBoostPending = (status: StatusResponse) =>
  isBoostedChannel(status) && !isBoostSkipped(status) && !isSwapBoosted(status);

export const isVaultSwap = (status: StatusResponse) =>
  status.integration === 'chainflip' && !status.integrationData?.depositChannel;

export const isSwapExecuted = (status: StatusResponse) => {
  if (status.integrationData && 'swap' in status.integrationData && status.integrationData.swap) {
    const { swap } = status.integrationData;
    if (!swap.isDca) return Boolean(swap.executedAt);
    return (
      swap.totalChunksExecuted ===
      (status.integrationData.depositChannel.dcaParams?.numberOfChunks ?? 1)
    );
  }
  return false;
};

export const getSwapEgress = (status: StatusResponse) =>
  status.integrationData &&
  'swapEgress' in status.integrationData &&
  status.integrationData.swapEgress
    ? status.integrationData.swapEgress
    : undefined;

export const getSwapScheduledAt = (status: StatusResponse) => {
  if (status.integrationData && 'swap' in status.integrationData && status.integrationData.swap)
    if (
      'currentChunk' in status.integrationData.swap &&
      status.integrationData.swap.currentChunk.scheduledAt
    ) {
      return status.integrationData.swap.currentChunk.scheduledAt;
    } else if ('scheduledAt' in status.integrationData.swap) {
      return status.integrationData.swap.scheduledAt; // vault swap
    }
  return undefined;
};

export const getSwapFees = (status: StatusResponse) =>
  status.integrationData && 'swap' in status.integrationData && status.integrationData.swap
    ? status.integrationData.swap.fees
    : undefined;

export const getEgressAmount = (sdkStatus: SwapStatusResponseV2 | undefined) =>
  sdkStatus && 'swapEgress' in sdkStatus && sdkStatus.swapEgress
    ? sdkStatus.swapEgress.amount
    : undefined;

export const getSwapDuration = (sdkStatus: SwapStatusResponseV2 | undefined) =>
  sdkStatus && 'swapEgress' in sdkStatus && sdkStatus.swapEgress && sdkStatus.depositChannel
    ? sdkStatus.swapEgress.scheduledAt - sdkStatus.depositChannel.createdAt
    : undefined;
