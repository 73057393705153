const Send = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1229_107820)">
      <path
        d="M2.66659 6.89176L12.9196 3.47408L9.50195 13.7271L7.73419 8.65953L2.66659 6.89176Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.85208 8.54166L9.14844 7.2453"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1229_107820">
        <rect width="16" height="16" fill="white" transform="translate(0 0.0563965)" />
      </clipPath>
    </defs>
  </svg>
);

export default Send;
