import classNames from 'classnames';
import copyAnimationData from '@/shared/animations/copy.json';
import { copy } from '@/shared/utils';
import Lottie from '@/shared/utils/Lottie';

interface CopyButtonProps {
  textToCopy: string;
  size?: Size;
}

type Size = 'small' | 'large';

const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  size = 'large',
  ...props
}): JSX.Element => {
  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    (event.currentTarget.childNodes[0] as HTMLElement).click();
  };

  const lottie = (
    <Lottie
      as="button"
      className="h-4 w-4"
      animationData={copyAnimationData}
      autoplay={false}
      speed={1}
      loop={false}
      onClick={(e) => {
        e.stopPropagation();
        copy(textToCopy);
      }}
      playOnce
      {...props}
    />
  );

  return (
    <div
      className={classNames(
        size === 'small' ? 'h-5 w-5 min-w-[20px]' : 'h-6 w-6 min-w-[24px]',
        'flex cursor-pointer items-center justify-center rounded border border-cf-gray-5 bg-cf-gray-4 text-12 text-cf-light-2 transition hover:bg-cf-gray-5',
      )}
      onClick={handleClick}
    >
      {lottie}
    </div>
  );
};
export default CopyButton;
