import { useEffect, useRef } from 'react';
import qrcode from 'qrcode';

export const QRCode = ({
  content,
  width,
  icon,
}: {
  content: string;
  width: number;
  icon?: React.ReactNode;
}) => {
  const ref = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (ref.current && width) {
      qrcode.toCanvas(ref.current, content, {
        margin: 1,
        width,
        color: { light: '#ffffff00', dark: '#161616ff' },
      });
    }
  }, [content, width]);

  return (
    <div className="flex items-center justify-center">
      {icon && (
        <div className="absolute flex h-[34px] w-[34px] items-center justify-center rounded-md bg-cf-gray-3">
          {icon}
        </div>
      )}
      <canvas className="w-full" ref={ref} />
    </div>
  );
};
