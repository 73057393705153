import React, { useMemo } from 'react';
import { type ChainflipNetwork } from '@chainflip/sdk/swap';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';

const cfToSolanaNetwork = {
  mainnet: WalletAdapterNetwork.Mainnet,
  perseverance: WalletAdapterNetwork.Devnet,
  sisyphos: WalletAdapterNetwork.Devnet,
  backspin: WalletAdapterNetwork.Testnet,
  unknown: WalletAdapterNetwork.Testnet,
} satisfies Record<ChainflipNetwork | 'unknown', WalletAdapterNetwork>;

export const Provider = ({
  cfNetwork,
  children,
}: {
  cfNetwork: ChainflipNetwork | 'unknown';
  children: React.ReactNode;
}) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = cfToSolanaNetwork[cfNetwork];

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={[]} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
