import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { type SwapStatusResponseV2 } from '@chainflip/sdk/swap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { type Integration, integrationManager, isSupportedIntegration } from '../integrations';
import { isDepositReceived } from '../utils/sdk';

export default function useSwapStatus() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { integration, swapId } = router.query;
  const [isEnabled, setIsEnabled] = useState(true);
  const retainOldQuote = useRef(true);

  const {
    data: status,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['swapStatus', integration, swapId],
    queryFn: async () =>
      (await integrationManager.getStatus(integration as Integration, swapId as string)) ?? null,
    refetchInterval: 5000,
    enabled: isEnabled && isSupportedIntegration(integration) && typeof swapId === 'string',
    structuralSharing: (oldData, newData) => {
      if (!oldData) return newData;

      if (oldData?.integration === 'chainflip' && newData?.integration === 'chainflip') {
        // If egress amount is 0, avoid updating the quote
        // This is an edge case where LPs are rebalancing and there are no orders in the current block
        // The swap would anyways not occur at this rate due to the protocol slippage protection
        if (newData.route.destAmount.eq(0)) {
          // eslint-disable-next-line no-param-reassign
          newData.route = oldData.route;
        }

        if (retainOldQuote.current) {
          const state = newData.integrationData?.state;
          // check if the route is actually only a quote
          if (state === 'RECEIVING' || isDepositReceived(newData)) {
            // eslint-disable-next-line no-param-reassign
            newData.route = oldData.route;
          }
        }
      }

      retainOldQuote.current = true;

      // chainflip state changes from AWAITING_DEPOSIT to BROADCAST_REQUESTED in one step most times
      // we want to interpolate the states in between to make the experience nicer for the user
      if (
        oldData?.integration === 'chainflip' &&
        newData?.integration === 'chainflip' &&
        newData.integrationData?.state === 'SENDING'
      ) {
        if (
          !oldData.integrationData || // vault contract swaps have no integration data until deposit is witnessed
          oldData?.integrationData?.state === 'RECEIVING'
        ) {
          // eslint-disable-next-line no-param-reassign
          (newData.integrationData as SwapStatusResponseV2).state = 'SWAPPING';
        }
      }

      return newData;
    },
  });

  const refreshStatus = () => {
    retainOldQuote.current = false;
    refetch();
  };

  const isWaitingRoomStatus =
    status != null &&
    status.integration === 'chainflip' &&
    status.integrationData?.state === 'SENDING';

  useEffect(() => {
    const swapIsFinished =
      (status?.integration !== 'chainflip' && status?.status === 'completed') ||
      (status?.integration === 'chainflip' && status?.integrationData?.state === 'COMPLETED') ||
      status?.status === 'failed';

    setIsEnabled(!swapIsFinished);
  }, [status]);

  // prevent loading state when updating url to include shareable id
  useEffect(() => {
    if (
      status?.shareableId &&
      !queryClient.getQueryData(['swapStatus', integration, status.shareableId])
    ) {
      queryClient.setQueryData(['swapStatus', integration, status.shareableId], status);
    }
  }, [status?.shareableId]);

  return {
    refreshStatus,
    isWaitingRoomStatus,
    status,
    isLoading: isLoading || !router.isReady,
  };
}
